import { CodeMirrorOptions } from '@nexweb/shared/ui/code-mirror';

export enum GridInputTypes {
  number,
  email,
  phoneNumber,
  internationalPhoneNumber,
  date,
  time,
  dateTime,
  boolean,
  string,
  password,
  select,
  textArea,
  label,
  switch,
  upload,
  fileSelect,
  checkbox,
  colorPicker,
  multiColumnSelect,
  multiSelect,
  hidden,
  editor,
  codeMirror,
}

export enum GridInputEditTypes {
  all,
  add,
  edit,
  none,
  hidden,
  label,
  placeholder,
  invisible, //hidden but included in the DOM
}

export enum DateFiltering {
  gte = 'gte',
  lte = 'lte',
  eq = 'eq',
}

/**
 * @deprecated Please use calendarView instead
 */
export enum DateView {
  daily = 'month',
  monthly = 'year',
  annually = 'decade',
}

export enum CalendarViewType {
  month = 'month',
  year = 'year',
  decade = 'decade',
  century = 'century',
}

export class CalendarView {
  activeView?: CalendarViewType;
  bottomView?: CalendarViewType;
  topView?: CalendarViewType;
}

export interface CalendarTimeStepIntervalConfig {
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}

export interface ColourPickerConfig {
  type?: ColorPickerTypes;
  format?: ColorPickerFormats;
  gradientSetting?: ColorPickerGradientSettings;
}

export interface FileSelectConfig {
  allowMultipleFiles?: boolean;
  allowedExtensions?: string[];
  maxFileSize?: number;
  minFileSize?: number;
}

export enum ColorPickerTypes {
  palette = 'palette',
  gradient = 'gradient',
}

export enum ColorPickerFormats {
  rgba = 'rgba',
  hex = 'hex',
}

export interface ColorPickerGradientSettings {
  clearButton: boolean;
  delay: number;
  opacity: boolean;
}

export enum ValueUpdateOn {
  blur = 'blur',
  submit = 'submit',
}

/**
 * Select Config
 */

/**
 * MultiSelect Config
 */

/**
 * Number Config
 */
export interface NumberConfig {
  decimals?: number;
}

/**
 * Textarea Config
 */
export class TextAreaConfig {
  rows? = 4;
}

export class BooleanConfig {
  trueLabel?: string;
  falseLabel?: string;
  asIcons?: boolean;
}

export class CodeMirrorConfig {
  options?: CodeMirrorOptions;
}
