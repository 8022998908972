import { Pipe, PipeTransform } from '@angular/core';

import { CypressMarkerTypes } from '../models';
import { CypressNamePipeService } from './cypress-name-pipe.service';

@Pipe({
  name: 'cypressName',
})
export class CypressNamePipe implements PipeTransform {
  constructor(private cypressNamePipeService: CypressNamePipeService) {}

  transform(name: string, type: CypressMarkerTypes | string, index?: number): string {
    let cypressType: CypressMarkerTypes;

    if (typeof type === 'string') {
      const typeIndex = Object.values(CypressMarkerTypes).indexOf(type);

      if (typeIndex !== -1) {
        cypressType = typeIndex as CypressMarkerTypes;
      } else {
        cypressType = CypressMarkerTypes.component;
      }
    } else {
      cypressType = type;
    }

    return this.cypressNamePipeService.cypressMarker(name, cypressType, index);
  }
}
