import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { isNullOrUndefined } from '@abp/ng.core';
import { SizeProp } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ButtonFillMode, ButtonThemeColor } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'nexweb-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() disabled;
  @Input() icon?: IconDefinition;
  @Input() title: string;
  @Input() primary: boolean;
  @Input() class: string;
  @Input() look: string;
  @Input() size: SizeProp;
  @Input() tooltip: string;
  @Input() showLoader: boolean;
  @Input() fillMode: ButtonFillMode;
  @Input() themeColor: ButtonThemeColor;
  @Output() clicked = new EventEmitter<unknown>();

  public cypressMarker: string;

  constructor() {}

  ngOnInit(): void {
    if (isNullOrUndefined(this.fillMode)) {
      this.fillMode = (this.look || 'clear') as ButtonFillMode;

      if (this.primary) {
        this.fillMode = 'solid' as ButtonFillMode;
      }
    }

    if (isNullOrUndefined(this.themeColor)) {
      this.themeColor = 'secondary';
    }
  }

  public clickHandler(event: unknown) {
    this.clicked.emit(event);
  }
}
