import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalizationModule } from '@abp/ng.core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { SharedUiPipesCypressNameModule } from '@nexweb/shared/ui/pipes/cypress-name';

import { ButtonComponent } from './button.component';

@NgModule({
  imports: [
    CommonModule,
    LocalizationModule,
    SharedUiPipesCypressNameModule,
    FontAwesomeModule,
    IndicatorsModule,
    TooltipsModule,
    ButtonModule,
  ],
  exports: [ButtonComponent],
  declarations: [ButtonComponent],
})
export class SharedUiButtonModule {}
