import { Injectable } from '@angular/core';

import { isNullOrUndefined } from '@nexweb/util';

import { CypressMarkerTypes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CypressNamePipeService {
  constructor() {}

  //set marker
  public cypressMarker(name: string, type: CypressMarkerTypes, index?: number, prefix = 'nexweb') {
    return this._cypressIniqueIdentifier(name, `${CypressMarkerTypes[type]}`, prefix, index);
  }

  private _cypressIniqueIdentifier(
    name: string,
    elementType: string = 'control',
    prefix: string = 'nexweb',
    index?: number
  ): string {
    if (isNullOrUndefined(name)) return ``;

    return `${prefix}-${elementType}-${name?.replace(/\s/g, '')}${!isNullOrUndefined(index) ? `-` : ``}${
      !isNullOrUndefined(index) ? `${index}` : ``
    }`;
  }
}
