import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';

import { GridInputTypes, TooltipSettings } from '../models';
import { GridColumn, GridColumnTypes } from '../models';
import { ColumnService } from '../services';

@Component({
  selector: 'nexweb-column',
  templateUrl: './column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent implements OnInit {
  @Input() dataItem: unknown;
  @Input() column!: GridColumn;
  @Input() toolTipVisible!: boolean;

  gridInputTypes = GridInputTypes;
  //gridCommandTypes = GridCommandTypes;
  gridColumnTypes = GridColumnTypes;

  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(private columnService: ColumnService) {}

  ngOnInit(): void {
    if (this.column.tooltip === true || (<TooltipSettings>this.column.tooltip)?.visible === true) {
      this.toolTipVisible = true;
    }
  }

  /**
   * Get Selected value Item
   *
   * @param dataItem
   * @param column
   * @returns
   */
  public getSelectDataItemValue(dataItem: unknown, column: GridColumn): Observable<unknown> {
    return this.columnService.getSelectDataItemValue(dataItem, column);
  }

  /**
   * Get Selected value Item
   *
   * @param dataItem
   * @param column
   * @returns
   */
  public getMultiSelectDataItemValue(dataItem: unknown, column: GridColumn): Observable<unknown> {
    return this.columnService.getMultiSelectDataItemValue(dataItem, column);
  }

  /**
   * getDataItemValue
   */
  public getDataItemValue(dataItem: unknown, column: GridColumn): unknown {
    return this.columnService.getDataItemValue(dataItem, column);
  }

  /**
   * Column template
   * @param template
   * @param column
   * @param dataItem
   */
  getAsTemplate(
    template: (column?: GridColumn, dataItem?: unknown) => string | Observable<string>,
    column: GridColumn,
    dataItem: unknown,
  ): string {
    return this.columnService.getAsTemplate(template, column, dataItem) as string;
  }

  /**
   * File Template
   * @param dataItem
   * @param column
   * @returns
   */
  public getDataFileSelectValue(dataItem: unknown, column: GridColumn): File[] {
    return this.columnService.getDataFileSelectValue(dataItem, column);
  }
}
