import { Component, Input, OnInit } from '@angular/core';

import { faCloudDownloadAlt, faFile } from '@fortawesome/pro-solid-svg-icons';
import { saveAs } from 'file-saver';

import { IconsService } from '@nexweb/shared/icon-helper';
import { isNullOrUndefined } from '@nexweb/util';

@Component({
  selector: 'nexweb-file-for-download',
  templateUrl: './file-for-download.component.html',
})
export class FileForDownloadComponent implements OnInit {
  @Input() file: File;
  public icon = faFile;
  public fileLabel: string;
  public downloadIcon = faCloudDownloadAlt;

  constructor(private iconsService: IconsService) {}

  ngOnInit() {
    this._initialize();
  }

  public fileClickHandler(): void {
    saveAs(this.file);
  }

  private _initialize() {
    this.downloadIcon = faCloudDownloadAlt;

    const fileNameAsAry = this.file.name?.split(`.`);
    const fileExtensionIndex = fileNameAsAry.length - 1; // last element in array

    if (!isNullOrUndefined(fileNameAsAry[fileExtensionIndex])) {
      const iconFromFileName = this.iconsService.getIconByExtension(fileNameAsAry[fileExtensionIndex]);

      this.icon = iconFromFileName ? iconFromFileName : faFile;
    }

    this.fileLabel = `${this.file.name} ${this.file.size}Kb`;
  }
}
