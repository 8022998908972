import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@abp/ng.core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { SharedUiCodeMirrorModule } from '@nexweb/shared/ui/code-mirror';
import { SharedUiFileViewForDownloadModule } from '@nexweb/shared/ui/file-view-for-download';
import { SharedUiPipesCypressNameModule } from '@nexweb/shared/ui/pipes/cypress-name';
import { SharedUiPipesSafeHtmlModule } from '@nexweb/shared/ui/pipes/safe-html';

import { ColumnComponent } from './components';
import { GridColumnFormatPipe } from './pipes/grid-column-format.pipe';
import { TooltipTitlePipe } from './pipes/tooltip-title.pipe';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    SharedUiPipesCypressNameModule,
    SharedUiPipesSafeHtmlModule,
    SharedUiFileViewForDownloadModule,
    SharedUiCodeMirrorModule,
    FontAwesomeModule,
    TooltipsModule,

    SharedUiPipesCypressNameModule,
  ],
  declarations: [ColumnComponent, GridColumnFormatPipe, TooltipTitlePipe],
  exports: [ColumnComponent, TooltipTitlePipe],
})
export class SharedUiColumnModule {}
