<span kendoTooltip filter="button">
  <button
    kendoButton
    [fillMode]="fillMode"
    class="k-button button-padded {{ primary ? 'k-primary' : '' }} {{ class }}"
    [disabled]="disabled"
    (click)="clickHandler($event)"
    [themeColor]="themeColor"
    [title]="tooltip ? (tooltip | abpLocalization) : ''"
    [attr.data-cy]="title ? (title | abpLocalization) : ('' | cypressName: 'button')">
    <kendo-loader *ngIf="showLoader" [size]="'small'"></kendo-loader>
    <fa-icon [icon]="icon" [size]="size" *ngIf="icon"></fa-icon>
    {{ title | abpLocalization }}
  </button>
</span>
