import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalizationService, isNullOrUndefined } from '@abp/ng.core';
import { Confirmation, ConfirmationService, CustomHttpErrorHandlerService } from '@abp/ng.theme.shared';
import { CUSTOM_HTTP_ERROR_HANDLER_PRIORITY } from '@abp/ng.theme.shared';

import { ValidationError } from '../models/validation-error.model';
import { CustomErrorStoreHandlerService } from './custom-error-store-handler.service';

@Injectable({ providedIn: 'root' })
export class CustomErrorHandlerService implements CustomHttpErrorHandlerService {
  readonly priority = CUSTOM_HTTP_ERROR_HANDLER_PRIORITY.veryHigh;

  private _error: HttpErrorResponse | undefined = undefined;

  constructor(
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService,
    private customErrorStoreHandlerService: CustomErrorStoreHandlerService,
  ) {}

  //handle error
  public canHandle(error: unknown): boolean {
    if (error instanceof HttpErrorResponse && (error.status === 500 || error.status === 400 || error.status === 403)) {
      this._error = error;

      return true;
    }

    return false;
  }

  // If this service is picked from ErrorHandler, this execute method will be called.
  public execute() {
    let heading = ``;
    let message = this._error.error?.error?.message || 'Bad request!';

    if (!isNullOrUndefined(this._error?.error?.error)) {
      const errorDetails = this._error.error.error.details;

      if (
        errorDetails.toString().includes(`ArgumentException`) ||
        errorDetails.toString().includes(`AbpValidationException`)
      ) {
        heading = this.localizationService.instant('NexBase::Title:ArgumentException');
      }

      //build validation error message
      if (this._error.status === 400 || this._error.status === 403) {
        if (!isNullOrUndefined(this._error.error.error?.validationErrors)) {
          message = this._error.error.error?.validationErrors
            .map((element: ValidationError) => element.message)
            .join('<br/');
        }
      }
    }

    this.confirmationService.error(message, heading, this._confirmationOptions());

    this.customErrorStoreHandlerService.error$.next(Object.assign({}, this._error));
  }

  //confirmation options
  private _confirmationOptions() {
    const closeText = this.localizationService.instant('NexBase::Title:Close');

    return {
      hideCancelBtn: true,
      yesText: closeText,
    } as Confirmation.Options;
  }
}
