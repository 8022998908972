import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CypressNamePipe } from './pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [CypressNamePipe],
  exports: [CypressNamePipe],
})
export class SharedUiPipesCypressNameModule {}
