import { FormGroup } from '@angular/forms';

import { DataItem } from '@progress/kendo-angular-grid';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { Observable } from 'rxjs';

// import { GridService } from '@nexweb/shared/ui/grid-services';
import { GridColumnProperties } from './grid-column-properties';
import { GridColumnValidation } from './grid-column-validation';
import { GridCommandButton } from './grid-command';
import { GridEditItem } from './grid-edit-item';
import { GridInputEditTypes, GridInputTypes, ValueUpdateOn } from './grid-input-types';
import { GridTypeConfig } from './grid-type-config';
import { TooltipSettings } from './tooltip-settings';

export class GridColumn {
  field: string;
  title?: string;
  description?: string;
  width?: number;
  minResizableWidth?: number;
  type?: GridColumnTypes = GridColumnTypes.main;
  //default value is used to set default when creating a new object
  //or the default value in the filter panel
  defaultValue?: string | number | Date | boolean | unknown[];
  // if value is null then display this text
  placeholderText?: string;
  //if setDefaultValue = true will always set the default value (view, add, edit)
  setDefaultValue? = false;
  hidden? = false;
  readonly? = false;
  excludeFromExport? = false;
  order? = 0;
  editOrder? = 0;
  maxLength? = 0;
  disabled?: boolean;
  inputType?: GridInputTypes = GridInputTypes.string;
  inputEditable?: GridInputEditTypes = GridInputEditTypes.all;
  typeConfig?: GridTypeConfig;
  properties? = { filterable: true } as GridColumnProperties;
  validations?: GridColumnValidation;

  template?: (column?: GridColumn, dataItem?: unknown, gridService?: unknown) => string | Observable<string>;
  /**
   * Provide a function to set the cell styles dynamically, return an object with the style properties that conforms
   * to ngStyle directive. see https://angular.io/api/common/NgStyle
   */
  cellStyles?: (column?: GridColumn, dataItem?: unknown) => Record<string, string>;

  hideableSettings?: HideableSettings;

  tooltip?: boolean | TooltipSettings;

  align?: ColumnAlign;
  headerAlign?: ColumnAlign;

  valueChangeHandler?: (
    column?: GridColumn,
    value?: unknown,
    dataItem?: GridEditItem | DataItem,
    form?: FormGroup,
  ) => void;

  blurChangeHandler?: (
    column?: GridColumn,
    value?: unknown,
    dataItem?: GridEditItem | DataItem,
    form?: FormGroup,
  ) => void;

  focusEventHandler?: (
    column?: GridColumn,
    value?: unknown,
    dataItem?: GridEditItem | DataItem,
    form?: FormGroup,
  ) => void;

  fileUploadEventHandler?: (column: GridColumn, event: SelectEvent) => void;

  params?: unknown; /* store extra params here */

  contextMenu?: ContextMenu;

  isDataLoading?: boolean;
  valueUpdateOn?: ValueUpdateOn; /* Set value update on */
}

export interface GridCellClick {
  sender: unknown;
  rowIndex: number;
  columnIndex: number;
  dataItem: unknown;
  isEdited: boolean;
}

export interface ContextMenu {
  click: MouseClicks;
  commandMenus: GridCommandButton[];
  enabled?: boolean;
}

export interface HideableSettings {
  hideable?: boolean;
  loadAsShown?: boolean;
}

export enum GridColumnTypes {
  main,
  detail,
  hidden,
  all,
}

export enum ColumnSortTypes {
  byOrder,
  byEditOrder,
  none,
}

export enum MouseClicks {
  left,
  right,
}

export enum ColumnAlign {
  left,
  center,
  right,
}
