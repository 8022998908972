import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';

import { SharedUiPipesCypressNameModule } from '@nexweb/shared/ui/pipes/cypress-name';

import { CodeMirrorComponent } from './code-mirror/code-mirror.component';

@NgModule({
  imports: [FormsModule, CommonModule, CodemirrorModule, SharedUiPipesCypressNameModule],
  declarations: [CodeMirrorComponent],
  exports: [CodeMirrorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedUiCodeMirrorModule {}
