<ngx-codemirror
  [(ngModel)]="value"
  (ngModelChange)="valueChangeHandler($event)"
  [class]="options.readOnly === 'nocursor' ? 'disabled' : ''"
  [options]="{
    mode: options.mode,
    indentWithTabs: options.indentWithTabs,
    smartIndent: options.smartIndent,
    lineNumbers: options.lineNumbers,
    matchBrackets: options.matchBrackets,
    autofocus: options.autofocus,
    extraKeys: options.extraKeys,
    autoRefresh: options.autoRefresh,
    readOnly: options.readOnly
  }"
  [attr.data-cy]="'codeMirrorContainer' | cypressName: 'component'"
  ngDefaultControl></ngx-codemirror>
