import { Injectable, OnDestroy } from '@angular/core';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { deepCopy, findFirstProperty, isNullOrUndefined } from '@nexweb/util';

import { GridColumn, GridInputTypes, OrderByColumn } from '../models';
import { ColumnDataStore } from '../models/column-data-store.model';

@Injectable({
  providedIn: 'root',
})
export class ColumnDataService implements OnDestroy {
  private _data: ColumnDataStore[];
  private _subscriptions$: Subscription[];

  constructor() {
    this._data = [];
    this._subscriptions$ = [];
  }

  /**
   * Clear Stored cache
   *
   */
  public clearCache(): void {
    this._data = [];
    this._clearSubscriptions();

    this._data = [];
    this._subscriptions$ = [];
  }

  /**
   * Get Data As Observable
   *
   * @returns
   */
  public getDataAsObservable$(column: GridColumn, rebind?: boolean): Observable<unknown[] | null> {
    this._store(column, rebind);

    return (this._data.find((x) => x.name === column.field) as ColumnDataStore).data.asObservable();
  }

  /**
   * Get Data
   *
   * @returns
   */
  public getData(column: GridColumn, rebind?: boolean): unknown[] | null {
    this._store(column, rebind);

    const columnStore = this._data.find((x) => x.name === column.field);

    return columnStore ? columnStore.data.value : null;
  }

  /**
   * Manually storeData
   * @returns
   * @param column
   * @param data
   */
  public dataStore(column: GridColumn, data: unknown[]): void {
    if (isNullOrUndefined(this._data.find((x) => x.name === column.field) as ColumnDataStore)) {
      this._data.push({
        name: column.field,
        data: new BehaviorSubject<unknown[] | null>(data),
      });
    }

    (this._data.find((x) => x.name === column.field) as ColumnDataStore).data.next(data);
  }

  /**
   * Return list of observables
   * @param columns
   * @param orderByColumns
   */
  public getColumnLookupObservables(columns: GridColumn[], orderByColumns?: OrderByColumn[]): unknown[] {
    const ary: unknown[] = [];

    if (!isNullOrUndefined(orderByColumns)) {
      orderByColumns.forEach((orderBycolumn: OrderByColumn) => {
        const column = columns.find((x) => x.field === orderBycolumn.field);

        if (!isNullOrUndefined(column)) {
          switch (column.inputType) {
            case GridInputTypes.select:
            case GridInputTypes.multiColumnSelect:
              ary.push(column.typeConfig?.typeData);
              break;
            case GridInputTypes.multiSelect:
              ary.push(column.typeConfig?.multiSelectConfig?.data);
          }
        }
      });
    }

    return ary.length > 0 ? ary : [of([])];
  }

  /**
   * Sort by columns
   *
   */
  public orderByColumns(
    data: unknown[],
    lookupData: unknown[][],
    columns: GridColumn[],
    orderColumns: OrderByColumn[],
  ): unknown[] {
    const orderByAry: SortDescriptor[] = [];

    orderColumns.forEach((orderByColumn: OrderByColumn, index) => {
      const column = columns.find((x) => x.field === orderByColumn.field);

      if (!isNullOrUndefined(column)) {
        switch (column.inputType) {
          case GridInputTypes.select:
          case GridInputTypes.multiColumnSelect:
          case GridInputTypes.multiSelect: {
            //add reference column
            let valueField = column.typeConfig?.typeValueField;
            let textField = column.typeConfig?.typeTextField;

            if (column.inputType === GridInputTypes.multiSelect) {
              valueField = column.typeConfig?.multiSelectConfig?.valueField;
              textField = column.typeConfig?.multiSelectConfig?.textField;
            }

            data = this._addDataReferenceColumn(data, lookupData[index], column, orderByColumn, valueField, textField);

            orderByAry.push({
              field: `orderby_${orderByColumn.field}_${textField}`,
              dir: orderByColumn.direction ? orderByColumn.direction : 'asc',
            });

            break;
          }

          default:
            orderByAry.push({
              field: `${orderByColumn.field}`,
              dir: orderByColumn.direction ? orderByColumn.direction : 'asc',
            });
            break;
        }
      }
    });

    data = orderBy(data, orderByAry);

    return data;
  }

  /**
   * Get Data From Result
   *
   * @param result
   * @param addAll
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDataFromResult(result: any, addAll?: any): unknown[] {
    let data = deepCopy(result);

    if (!isNullOrUndefined(result)) {
      if (result !== null) {
        if (result.items) {
          data = result.items;
        }

        if (addAll) {
          const exists = (data as unknown[]).find(
            (x) => x[findFirstProperty(x, addAll)] === addAll[findFirstProperty(x, addAll)],
          );

          if (exists === undefined) {
            data = [addAll, ...data];
          }
        }
      }
    }

    return data;
  }

  public getDefaultSelectedItem(data: unknown[], column: GridColumn): unknown | undefined {
    let result: unknown | undefined;
    //select value by given value
    if (column.typeConfig?.defaultItemValue !== undefined) {
      const dataItemByValue = data.find(
        (x) => x[column.typeConfig?.typeValueField] === column.typeConfig?.defaultItemValue,
      );

      //by value sets first, default to by index
      if (!isNullOrUndefined(dataItemByValue)) {
        result = dataItemByValue[column.typeConfig?.typeValueField];
      }
    }

    //by index
    if (column.typeConfig?.defaultItemIndex !== undefined) {
      if (!isNullOrUndefined(column.typeConfig?.typeValueField)) {
        result = data[column.typeConfig?.defaultItemIndex][column.typeConfig?.typeValueField];
      } else {
        result = data[column.typeConfig?.defaultItemIndex];
      }
    }

    //single entry hidden
    if (
      (!!isNullOrUndefined(column.typeConfig?.hideIfDataContainsOneItem) ||
        !!isNullOrUndefined(column.typeConfig?.disableIfDataContainsOneItem)) &&
      data.length === 1
    ) {
      if (!isNullOrUndefined(column.typeConfig?.typeValueField)) {
        result = data[0][column.typeConfig?.typeValueField];
      } else {
        result = data[0];
      }
    }

    return result;
  }

  public getDefaultMultiSelectedItem(data: unknown[], column: GridColumn): unknown[] | undefined {
    let result: unknown[] | undefined;

    //select value by given value
    if (
      column.typeConfig?.multiSelectConfig?.defaultItemValue !== undefined &&
      column.typeConfig?.multiSelectConfig?.defaultItemValue.length > 0
    ) {
      const dataItemsByValues: unknown[] = [];

      column.typeConfig.multiSelectConfig.defaultItemValue.forEach((x) => {
        dataItemsByValues.push(data.find((y) => y[column.typeConfig?.multiSelectConfig?.valueField] === x));
      });

      const value: unknown[] = [];
      if (dataItemsByValues.length > 0) {
        dataItemsByValues.forEach((x) => {
          value.push(x[column.typeConfig?.multiSelectConfig?.valueField]);
        });
      }

      result = value;
    }

    //by index
    if (
      column.typeConfig?.multiSelectConfig?.defaultItemIndex !== undefined &&
      column.typeConfig?.multiSelectConfig?.defaultItemIndex.length > 0
    ) {
      if (!isNullOrUndefined(column.typeConfig?.multiSelectConfig.valueField)) {
        const value = [];
        column.typeConfig?.multiSelectConfig?.defaultItemIndex.forEach((index) => {
          value.push(data[index][column.typeConfig?.multiSelectConfig.valueField]);
        });
        result = value;
      } else {
        result = [];
      }
    }

    return result;
  }

  /**
   * Transform TypeData depending on configuration
   *
   * @param result
   * @param column
   * @returns
   */
  public transformTypeData(result: unknown, column: GridColumn): unknown[] {
    const data: unknown[] = this.getDataFromResult(result, column.typeConfig.allItem);

    let textField = column.typeConfig.typeTextField;
    let valueField = column.typeConfig.typeValueField;
    let sorted = column.typeConfig.sorted;

    if (!isNullOrUndefined(column.typeConfig.multiSelectConfig)) {
      textField = column.typeConfig.multiSelectConfig.textField;
      valueField = column.typeConfig.multiSelectConfig.valueField;
      sorted = column.typeConfig.multiSelectConfig.sorted;
    }

    if (data !== undefined) {
      if (data !== null) {
        //sorting is configurable
        if (sorted) {
          data.sort((a, b) => {
            if (typeof a[textField] === 'string') {
              return a[textField].toLowerCase() > b[textField].toLowerCase() ? 1 : -1;
            } else {
              return a[textField] > b[textField] ? 1 : -1;
            }
          });

          // put item with id 0 first
          if (data.find((x) => x[valueField] === 0) !== undefined) {
            data.sort((a, b) => (a[valueField] === 0 ? -1 : b[valueField] === 0 ? 1 : 0));
          }
        }

        column.isDataLoading = false;

        if (data.length > 0) {
          return data;
        }
      }
    }

    return [];
  } //end

  public getSelectData(column: GridColumn, rebind = false): Observable<unknown[]> {
    //set loading
    column.isDataLoading = true;

    return this.getDataAsObservable$(column, rebind).pipe(map((result) => this.transformTypeData(result, column)));
  }

  ngOnDestroy(): void {
    delete this._data;
    this._clearSubscriptions();
  }

  /**
   * Store data
   *
   * @param column
   * @param rebind
   */
  private _store(column: GridColumn, rebind?: boolean): void {
    if (isNullOrUndefined(this._data)) {
      this._data = [];
    }

    if (isNullOrUndefined(this._subscriptions$)) {
      this._subscriptions$ = [];
    }

    if (!isNullOrUndefined(column.typeConfig?.typeData) || !isNullOrUndefined(column.typeConfig?.multiSelectConfig)) {
      if (rebind) {
        this._data = this._data.filter((x) => !(x.name === column.field));
      }

      if (isNullOrUndefined(this._data.find((x) => x.name === column.field) as ColumnDataStore)) {
        this._data.push({
          name: column.field,
          data: new BehaviorSubject<unknown[] | null>(null),
        });

        let lookupData = column.typeConfig?.typeData;

        if (!isNullOrUndefined(column.typeConfig?.multiSelectConfig)) {
          lookupData = column.typeConfig?.multiSelectConfig.data;
        }

        const _subscription = lookupData
          ?.pipe(
            map((data: unknown[]) => {
              const entry = this._data.find((x) => x.name === column.field) as ColumnDataStore;

              if (!isNullOrUndefined(entry)) {
                (this._data.find((x) => x.name === column.field) as ColumnDataStore).data.next(data);
              }
            }),
          )
          .subscribe();

        if (!isNullOrUndefined(_subscription)) {
          this._subscriptions$.push(_subscription);
        }
      }
    }
  }

  private _clearSubscriptions(): void {
    if (!isNullOrUndefined(this._subscriptions$)) {
      for (const item of this._subscriptions$) {
        item.unsubscribe();
      }
    }
  }

  /**
   * Add reference columns
   *
   * @param data
   * @param lookupData
   * @param column
   * @param orderByColumn
   * @param valueField
   * @param textField
   * @returns
   */
  private _addDataReferenceColumn(
    data: unknown[],
    lookupData: unknown[],
    column: GridColumn,
    orderByColumn: OrderByColumn,
    valueField: string | undefined,
    textField: string | undefined,
  ): unknown[] {
    const getData = this.getDataFromResult(lookupData);

    data.forEach((dataItem: unknown, index) => {
      if (!isNullOrUndefined(getData)) {
        const lookupEntry = getData.find((x) => x[valueField] === dataItem[orderByColumn.field]);

        data[index][`orderby_${orderByColumn.field}_${textField}`] = !isNullOrUndefined(lookupEntry)
          ? lookupEntry[textField]
          : '';
      }
    });

    return data;
  }
}
