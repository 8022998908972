import { Pipe, PipeTransform } from '@angular/core';

import { isNullOrUndefined } from '@abp/ng.core';

import { GridColumn, TooltipSettings } from '../models';

@Pipe({
  name: 'tooltipTitle',
})
export class TooltipTitlePipe implements PipeTransform {
  transform(column: GridColumn, showTooltips?: boolean, dataItem?: unknown): string {
    if (showTooltips === true || (<TooltipSettings>column.tooltip)?.visible === true || column.tooltip === true) {
      if (!isNullOrUndefined(column.tooltip)) {
        //activate display handler
        if (typeof column.tooltip === 'object') {
          if (column.tooltip?.displayHandler) {
            return column.tooltip?.displayHandler(column, dataItem);
          }
        }
      }

      if (!isNullOrUndefined(dataItem)) {
        if (!isNullOrUndefined(dataItem[column.field])) {
          return dataItem[column.field];
        }
      }
    }

    return ``;
  }
}
