import { Injectable } from '@angular/core';

import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { CalendarView } from '@progress/kendo-angular-dateinputs';
import { IntlService } from '@progress/kendo-angular-intl';
import { Day } from '@progress/kendo-date-math';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isArray, isNullOrUndefined } from '@nexweb/util';

import { ColumnDataService } from '.';
import { CalendarViewType, ColumnSortTypes, GridColumn, GridInputEditTypes, GridInputTypes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ColumnService {
  constructor(
    private columnDataService: ColumnDataService,
    private localizationService: LocalizationService,
    private intl: IntlService,
    private configStateService: ConfigStateService,
  ) {}

  public getDataItemValue(dataItem, column: GridColumn): unknown {
    switch (column.inputType) {
      case GridInputTypes.number:
        return dataItem[column.field] != null ? this.intl.parseNumber(dataItem[column.field]) : null;
      case GridInputTypes.date:
        if (dataItem[column.field] != null) {
          if (typeof dataItem[column.field] === 'string') {
            dataItem[column.field] = this.intl.parseDate(dataItem[column.field], ['G', 'd']);
          }
        }

        return dataItem[column.field];
      case GridInputTypes.boolean:
        return dataItem[column.field] !== null
          ? dataItem[column.field]
            ? column.typeConfig?.booleanConfig?.trueLabel
              ? this.localizationService.instant(column.typeConfig.booleanConfig?.trueLabel)
              : this.localizationService.instant('NexBase::Title:BooleanTrueLabel')
            : column.typeConfig?.booleanConfig?.falseLabel
              ? this.localizationService.instant(column.typeConfig.booleanConfig?.falseLabel)
              : this.localizationService.instant('NexBase::Title:BooleanFalseLabel')
          : ``;
      case GridInputTypes.password:
        return `*`.repeat(dataItem[column.field].length);

      default:
        return !isNullOrUndefined(dataItem[column.field]) ? dataItem[column.field] : '';
    }
  }

  /**
   * Get Select Data Item Value
   *
   * @param dataItem
   * @param column
   * @returns
   */
  public getSelectDataItemValue(dataItem: unknown, column: GridColumn): Observable<unknown> {
    let isLookup = column.typeConfig.isLookup;

    if (isNullOrUndefined(isLookup)) {
      isLookup = true;
    }

    // build lookup
    if (isLookup) {
      return this.columnDataService.getDataAsObservable$(column).pipe(
        map((result) => {
          const data: unknown[] = this.columnDataService.getDataFromResult(result);

          //when there's data
          if (!isNullOrUndefined(data)) {
            return this._getSelectDataItemValue(
              data,
              dataItem[column.field],
              column.typeConfig.typeValueField,
              column.typeConfig.typeTextField,
            );
          }

          return dataItem[column.field]; //end
        }),
      );
    } else {
      return of(dataItem[column.field]);
    }
  }

  /**
   * Get MultiSelect Data Item Value
   *
   * @param dataItem
   * @param column
   * @returns
   */
  public getMultiSelectDataItemValue(dataItem: unknown, column: GridColumn): Observable<unknown> {
    let dataItemAry = dataItem[column.field] as unknown[];

    if (!isNullOrUndefined(dataItemAry)) {
      if (!isArray(dataItemAry)) {
        dataItemAry = (dataItemAry as unknown as string).toString().split(',') as unknown[];
      }
    } else {
      dataItemAry = [];
    }

    // build lookup
    if (column.typeConfig.multiSelectConfig.isLookup) {
      return this.columnDataService.getDataAsObservable$(column).pipe(
        map((result) => {
          const data: unknown[] = this.columnDataService.getDataFromResult(result);

          //when there's data
          if (!isNullOrUndefined(data)) {
            const ary: string[] = [];

            dataItemAry.forEach((value: unknown) => {
              ary.push(
                this._getSelectDataItemValue(
                  data,
                  value,
                  column.typeConfig.multiSelectConfig.valueField,
                  column.typeConfig.multiSelectConfig.textField,
                ),
              );
            });

            return ary.join(',');
          }

          return []; //end
        }),
      );
    } else {
      return of(dataItemAry.join(','));
    }
  }

  getAsTemplate(
    template: (column?: GridColumn, dataItem?: unknown) => string | Observable<string>,
    column: GridColumn,
    dataItem: unknown,
  ): unknown {
    return template(column, dataItem);
  }

  showDateFilterOperators(column: GridColumn): boolean {
    if (column.typeConfig) {
      if (column.typeConfig.dateFiltering) {
        return false;
      }
    }

    return true;
  }

  getDateOperator(column: GridColumn): string {
    if (column.typeConfig) {
      if (column.typeConfig.dateFiltering) {
        return column.typeConfig?.dateFiltering.toString();
      }
    }

    return 'eq';
  }

  public getPlaceHolderConfig(column: GridColumn): string {
    let suggestion = '';

    if (column.typeConfig?.placeHolder) {
      suggestion = this.localizationService.instant({
        key: column.typeConfig?.placeHolder,
        defaultValue: '',
      });
    }

    return suggestion;
  }

  /**
   * Data File Select Value
   *
   * @param dataItem
   * @param column
   * @returns
   */
  public getDataFileSelectValue(dataItem: unknown, column: GridColumn): File[] {
    let files: File[];

    if (!isNullOrUndefined(column.typeConfig?.fileSelectConfig)) {
      if (column.typeConfig?.fileSelectConfig.allowMultipleFiles) {
        files = dataItem[column.field];
      }
    }

    if (isNullOrUndefined(files)) {
      if (!isNullOrUndefined(dataItem[column.field]) && isNullOrUndefined(dataItem[column.field]?.length)) {
        files = [dataItem[column.field]];
      } else {
        files = dataItem[column.field];
      }
    }

    return files;
  } //end

  /**
   * @param column
   * @returns
   */
  public format(column: GridColumn) {
    switch (column.inputType) {
      case GridInputTypes.date:
      case GridInputTypes.time:
      case GridInputTypes.dateTime:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return column.typeConfig.format;
          }
        }

        //use standard defined
        if (column.inputType === GridInputTypes.dateTime) {
          return this.configStateService.getSetting('NexBase.DateTimeFormat');
        }
        if (column.inputType === GridInputTypes.time) {
          return this.configStateService.getSetting('NexBase.TimeFormat');
        }

        return this.configStateService.getSetting('NexBase.DateFormat');

      case GridInputTypes.number:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return column.typeConfig.format;
          }
        }

        return '';

      default:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return column.typeConfig.format;
          }
        }

        return '';
    }
  }

  /**
   * See setActiveView for how method should be implemented
   * @param column
   * @returns
   */
  public setTopView(column: GridColumn): CalendarView {
    if (column.typeConfig) {
      if (column.typeConfig?.calendarView?.topView) {
        return column.typeConfig.calendarView.topView;
      }
    }

    return CalendarViewType.month;
  }

  /**
   * See setActiveView for how method should be implemented
   * @param column
   * @returns
   */
  public setBottomView(column: GridColumn): CalendarView {
    if (column.typeConfig) {
      if (column.typeConfig?.calendarView?.bottomView) {
        return column.typeConfig.calendarView.bottomView;
      }

      return CalendarViewType.month;
    }

    return CalendarViewType.month;
  }

  /**
   *
   * @param column
   */
  public setActiveView(column: GridColumn): CalendarView {
    if (column.typeConfig?.calendarView?.activeView) {
      return column.typeConfig.calendarView.activeView;
    }

    return CalendarViewType.month;
  }

  public setMaxDate(column: GridColumn): Date {
    if (column.typeConfig) {
      if (column.typeConfig.max) {
        const maximumDate = column.typeConfig.max as Date;
        maximumDate.setHours(23);
        maximumDate.setMinutes(59);
        maximumDate.setSeconds(59);

        return maximumDate;
      }
    }

    return undefined;
  }

  public setMinDate(column: GridColumn): Date {
    if (column.typeConfig) {
      if (column.typeConfig.min) {
        return column.typeConfig.min as Date;
      }
    }

    return undefined;
  }

  public setNumericStep(column: GridColumn) {
    if (column.typeConfig) {
      if (column.typeConfig.stepValue) {
        return column.typeConfig.stepValue;
      }

      return 1;
    }

    return 1;
  }

  /**
   *
   * @param column
   */
  public setDisabledDates(column: GridColumn): ((date: Date) => boolean) | Date[] | Day[] {
    if (column.typeConfig) {
      if (column.typeConfig?.disabledDates) {
        return column.typeConfig.disabledDates;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (date: Date) => false;
  }

  public getEditableType(column: GridColumn, isNew: boolean): boolean {
    if (column.inputEditable !== undefined) {
      if (isNew && column.inputEditable === GridInputEditTypes.edit) {
        return false;
      }

      if (!isNew && column.inputEditable === GridInputEditTypes.add) {
        return false;
      }

      if (column.inputEditable === GridInputEditTypes.none) return false;
      if (column.inputEditable === GridInputEditTypes.label) return false;
      if (column.inputEditable === GridInputEditTypes.placeholder) return false;
    }

    //set disabled
    if (column.disabled) return false;
    //set as readonly
    if (column.readonly) return false;

    return true;
  }

  /**TODO::TODI:Move to ColumnService
   * @deprecated The method should not be used
   *
   * Please use columnService instead
   */
  public getEditableVisibleType(column: GridColumn): boolean {
    if (column.inputEditable !== undefined) {
      if (column.inputEditable === GridInputEditTypes.hidden) return false;
    }

    return true;
  }

  /**
   * Check if column is editable
   *
   * @param column
   *
   * @param isNew
   * @returns
   */
  public isEditable(column: GridColumn, isNew: boolean): boolean {
    if (column.inputEditable !== undefined) {
      if (isNew && column.inputEditable === GridInputEditTypes.edit) {
        return false;
      }

      if (!isNew && column.inputEditable === GridInputEditTypes.add) {
        return false;
      }

      if (
        column.inputEditable === GridInputEditTypes.none ||
        column.inputEditable === GridInputEditTypes.label ||
        column.inputEditable === GridInputEditTypes.placeholder ||
        column.inputEditable === GridInputEditTypes.hidden
      ) {
        return false;
      }
    }

    //set disabled
    if (column.disabled) return false;
    //set as readonly
    if (column.readonly) return false;

    return true;
  }

  /**
   * Order Columns by Order Type
   *
   *
   * @param columns
   * @param orderType
   * @returns
   */
  public orderColumns(columns: GridColumn[], orderType: ColumnSortTypes = ColumnSortTypes.byOrder): GridColumn[] {
    switch (orderType) {
      case ColumnSortTypes.byOrder:
      case ColumnSortTypes.byEditOrder:
        return Object.assign(
          [],
          columns.sort((a, b) => {
            let first = a.order || 0;
            let second = b.order || 0;

            //byEditOrder
            if (orderType === ColumnSortTypes.byEditOrder) {
              first = a.editOrder || 0;
              second = b.editOrder || 0;
            }

            return first - second;
          }),
        );
      default:
        return columns;
    }
  }

  private _getSelectDataItemValue(data: unknown[], value: unknown, valueField: string, textField: string): string {
    const entry = data.find((x) => x[valueField] === value); // comparing unknown

    if (entry !== undefined) {
      return entry[textField];
    } else {
      if (data.length > 0) {
        return value as string;
      }
    }

    return null;
  }
}
