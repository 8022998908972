import { Pipe, PipeTransform } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import { IntlService } from '@progress/kendo-angular-intl';

import { GridColumn, GridInputTypes } from '../models';

@Pipe({
  name: 'gridColumnFormat',
})
export class GridColumnFormatPipe implements PipeTransform {
  constructor(private intlService: IntlService, private configStateService: ConfigStateService) {}

  transform(value: Date | string | number, column: GridColumn): Date | string | number {
    switch (column.inputType) {
      case GridInputTypes.date:
      case GridInputTypes.time:
      case GridInputTypes.dateTime:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return this.intlService.formatDate(value as Date, column.typeConfig.format);
          }
        }

        if (column.inputType === GridInputTypes.dateTime) {
          return this.intlService.formatDate(
            value as Date,
            this.configStateService.getSetting('NexBase.DateTimeFormat')
          );
        }
        if (column.inputType === GridInputTypes.time) {
          return this.intlService.formatDate(value as Date, this.configStateService.getSetting('NexBase.TimeFormat'));
        }

        return this.intlService.formatDate(value as Date, this.configStateService.getSetting('NexBase.DateFormat'));

      case GridInputTypes.number:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return this.intlService.formatNumber(value as number, column.typeConfig.format);
          }
        }

        return this.intlService.formatNumber(value as number, '');

      default:
        if (column.typeConfig) {
          if (column.typeConfig.format) {
            return this.intlService.format(value as string, column.typeConfig.format);
          }
        }

        return this.intlService.format(value as string);
    }
  }
}
