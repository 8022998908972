import { Injectable } from '@angular/core';

import * as fa from '@fortawesome/pro-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private _icons = fa;

  constructor() {}

  /**
   * Returns icons list
   */
  public getIcons(): typeof fa {
    return this._icons;
  }

  public getIconByExtension(name: string): fa.IconDefinition {
    switch (name) {
      case 'xls':
      case 'xlsx':
      case '.xls':
      case '.xlsx':
        return this._icons.faFileExcel;

      case 'txt':
      case '.txt':
        return this._icons.faFile;

      case 'pdf':
      case '.pdf':
        return this._icons.faFilePdf;
    }

    return {} as fa.IconDefinition;
  }
}
