import { Component, Input, OnChanges, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LOADING_STRATEGY, LazyLoadService } from '@abp/ng.core';
import { forkJoin } from 'rxjs';

import { CodeMirrorOptions } from './code-mirror-options';

@Component({
  selector: 'nexweb-code-mirror',
  templateUrl: './code-mirror.component.html',
  styleUrls: ['./code-mirror.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeMirrorComponent),
      multi: true,
    },
  ],
})
export class CodeMirrorComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() value: unknown = '';
  @Input() options!: CodeMirrorOptions;

  constructor(private lazyLoadService: LazyLoadService) {}

  public onTouched = () => {};

  public registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public ngOnChanges() {
    this.valueChangeHandler(this.value as string);
  }

  public valueChangeHandler(value: string): void {
    this.value = value;
    this.onTouched();
    this.propagateChange(value);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
    }
  }

  writeValue(obj: string): void {
    if (obj === undefined) {
      this.value = '-- Paste your code here...';
    }
    this.value = obj;
  }

  ngOnInit(): void {
    forkJoin([
      this.lazyLoadService.load(LOADING_STRATEGY.AppendAnonymousStyleToHead('/codemirror.min.css')),
      this.lazyLoadService.load(LOADING_STRATEGY.AppendAnonymousStyleToHead('/codemirror-show-hint.min.css')),
    ]).subscribe();
  }

  private propagateChange = (value: string) => {
    if (value) {
    }
  };
}
