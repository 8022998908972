export enum CypressMarkerTypes {
  column,
  filter,
  button,
  commandButton,
  component,
  kendoGrid,
  hotGrid,
  columnDetail,
  label,
  span,
  form,
}
