import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedUiButtonModule } from '@nexweb/shared/ui/button';

import { FileForDownloadComponent } from './file-for-download.component';

@NgModule({
  imports: [CommonModule, SharedUiButtonModule],
  exports: [FileForDownloadComponent],
  declarations: [FileForDownloadComponent],
})
export class SharedUiFileViewForDownloadModule {}
