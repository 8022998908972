<div>
  <span
    *ngIf="
      column.inputType === gridInputTypes.date ||
      column.inputType === gridInputTypes.time ||
      (column.inputType === gridInputTypes.dateTime && column.template === undefined)
    "
    [title]="column | tooltipTitle: toolTipVisible : dataItem"
    [attr.data-cy]="column.field | cypressName: 'column'"
    [innerHTML]="dataItem[column.field] | gridColumnFormat: column"></span>

  <!-- format boolean|select|password -->
  <ng-container
    *ngIf="
      column.inputType === gridInputTypes.boolean ||
      column.inputType === gridInputTypes.switch ||
      column.inputType === gridInputTypes.password ||
      column.inputType === gridInputTypes.select ||
      column.inputType === gridInputTypes.string ||
      column.inputType === gridInputTypes.colorPicker ||
      column.inputType === gridInputTypes.textArea ||
      column.inputType === gridInputTypes.label ||
      column.inputType === gridInputTypes.upload ||
      column.inputType === gridInputTypes.checkbox ||
      column.inputType === gridInputTypes.multiColumnSelect ||
      column.inputType === gridInputTypes.multiSelect ||
      column.inputType === gridInputTypes.fileSelect ||
      column.inputType === gridInputTypes.number ||
      column.inputType === gridInputTypes.codeMirror ||
      (!column.inputType && column.template === undefined)
    ">
    <ng-container [ngSwitch]="column.inputType" *ngIf="column.template === undefined">
      <!-- Boolean | Switch -->
      <span
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'"
        *ngSwitchCase="
          [gridInputTypes.boolean, gridInputTypes.switch].includes(column.inputType)
            ? column.inputType
            : !column.inputType
        ">
        <fa-icon
          [icon]="faCheck"
          [attr.data-cy]="column.field | cypressName: 'column'"
          *ngIf="column.typeConfig?.booleanConfig?.asIcons && dataItem[column.field] === true"></fa-icon>
        <fa-icon
          [icon]="faTimes"
          [attr.data-cy]="column.field | cypressName: 'column'"
          *ngIf="column.typeConfig?.booleanConfig?.asIcons && dataItem[column.field] === false"></fa-icon>
        <span
          *ngIf="!column.typeConfig?.booleanConfig?.asIcons"
          [attr.data-cy]="column.field | cypressName: 'column'"
          [innerHTML]="getDataItemValue(dataItem, column)"></span>
      </span>
      <!-- End Boolean -->
      <!-- Select -->
      <span
        *ngSwitchCase="gridInputTypes.select"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'"
        [innerHTML]="getSelectDataItemValue(dataItem, column) | async"></span>

      <!-- Multi ColumnSelect -->
      <span
        *ngSwitchCase="gridInputTypes.multiColumnSelect"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'"
        [innerHTML]="getSelectDataItemValue(dataItem, column) | async"></span>

      <!-- Multielect -->
      <span
        *ngSwitchCase="gridInputTypes.multiSelect"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'"
        [innerHTML]="getMultiSelectDataItemValue(dataItem, column) | async"></span>

      <!-- Password -->
      <span
        *ngSwitchCase="gridInputTypes.password"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'"
        [innerHTML]="getDataItemValue(dataItem, column)"></span>

      <!-- FileSelect -->

      <span
        *ngSwitchCase="gridInputTypes.fileSelect"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [attr.data-cy]="column.field | cypressName: 'column'">
        <ng-container *ngFor="let file of getDataFileSelectValue(dataItem, column); let i = index">
          <nexweb-file-for-download [file]="file"></nexweb-file-for-download>
          &nbsp;
        </ng-container>
      </span>

      <!-- Number -->
      <span
        *ngSwitchCase="gridInputTypes.number"
        [attr.data-cy]="column.field | cypressName: 'column'"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [innerHTML]="dataItem[column.field] | gridColumnFormat: column"></span>

      <!-- Default -->
      <span
        *ngSwitchDefault
        [attr.data-cy]="column.field | cypressName: 'column'"
        [title]="column | tooltipTitle: toolTipVisible : dataItem"
        [innerHTML]="dataItem ? dataItem[column.field] : ''"></span>
      <!-- End Default -->
    </ng-container>
  </ng-container>

  <!-- override value with template -->
  <span
    *ngIf="column.template !== undefined"
    [attr.data-cy]="column.field | cypressName: 'span'"
    [title]="column | tooltipTitle: toolTipVisible : dataItem"
    [innerHTML]="getAsTemplate(column.template, column, dataItem) | safeHtml"></span>
</div>
