import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { GridEditItem } from './grid-edit-item';

export interface GridCommand {
  disabled: boolean;
  title: string;
  width?: number;
  headerButtons?: GridHeaderButton[];
  columnButtons?: GridCommandButton[];
  commandMenus?: GridCommandButton[];
}

export interface GridCommandButton {
  icon?: IconDefinition;
  title?: string;
  command: GridCommandTypes;
  actionName: number | string;
  disabled?: boolean;
  validator?: (button: GridCommandButton, dataItem: unknown) => boolean;
  tooltip?: string;
}

export interface GridHeaderButton {
  icon?: IconDefinition;
  title?: string;
  command: GridCommandTypes;
  actionName: number | string;
  disabled?: boolean;
  validator?: (button: GridHeaderButton, settings: unknown, gridService?: unknown) => boolean;
  tooltip?: string;
}

export interface GridCommandEvent {
  action: GridCommandTypes | unknown;
  editItem?: GridEditItem;
  button?: GridCommandButton;
}

export enum GridCommandTypes {
  add,
  edit,
  remove,
  custom,
  exportToExcel,
  exportToPdf,
  saveChanges,
  cancelChanges,
}

export interface GridToolbar {
  title: string;
}
