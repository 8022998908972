import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorStoreHandlerService {
  public error$ = new BehaviorSubject<HttpErrorResponse | undefined>(null);
  private _formSaved = new BehaviorSubject<string>(undefined);

  public getFormSaved(): string {
    return this._formSaved.value;
  }

  public clearError() {
    this.error$.next(undefined);
  }

  public setFormSaved(id: string) {
    this._formSaved.next(id);
  }
}
